<template>
  <div v-if="obj" class="object">
    <div @mouseover="mouseOverTitle=true" @mouseleave="mouseOverTitle=false">
      <h1><EditableText v-model="title" :dirty="titleDirty" placeholder="NO TITLE" class="title"/></h1>
      <div class="work-icons">
        <font-awesome-icon v-if="working || workFailed" icon="cog" :spin="working" :class="{'work-failed': workFailed}" />
      </div>
      <div v-show="mouseOverTitle" class="tools">
        <font-awesome-icon @click="deleteObject" icon="trash-alt" class="delete" title="delete"/>
        <font-awesome-icon @click="showRaw = !showRaw" icon="code" title="show JSON"/>
      </div>
    </div>
    <EditableText v-model="text" :dirty="textDirty" placeholder="NO TEXT" class="text"/>
    <EditableText v-show="showRaw" v-model="rawJSON" :dirty="rawJSONDirty" class="raw-edit"/>
    <TagBox :obj="obj" @tagRemove="removeTag($event)" @tagAdd="addTag($event)"
            :editable="true" size="100%" placeholder="NO TAGS" />
    <div @mouseover="mouseOverButtons=true" @mouseleave="mouseOverButtons=false"
         :class="{buttons: true, 'buttons-no-mouse': !mouseOverButtons}">
      <button @click="addChild">add child</button>
      <button @click="clone">clone</button>
      <div class="buttons-right">
        <button @click="save" :disabled="!dirty">save</button>
        <button @click="reset" :disabled="!dirty">reset</button>
      </div>
    </div>
  </div>
</template>

<script>
import EditableText from '@/components/EditableText.vue'
import TagBox from '@/components/TagBox.vue'
import c from '@/const'

const attrVal = (obj, key, defVal) => {
  if (obj[key] === undefined) {
    return defVal
  }
  return obj[key].v
}

export default {
  name: 'Object',
  components: {
    EditableText,
    TagBox,
  },
  props: {
    obj: {type: Object, default: () => ({})},
  },
  data() {
    return {
      title: attrVal(this.obj, 'title', ''),
      text: attrVal(this.obj, 'text', ''),
      rawJSON: JSON.stringify(this.obj),
      showRaw: false,
      mouseOverTitle: false,
      mouseOverButtons: false,
      working: false,
      workFailed: false,
    }
  },
  computed: {
    objId() {
      return this.obj['.id'].v
    },
    dirty() {
      return this.titleDirty || this.textDirty || this.rawJSONDirty
    },
    savedTitle() {
      return attrVal(this.obj, 'title', '')
    },
    savedText() {
      return attrVal(this.obj, 'text', '')
    },
    savedRawJSON() {
      return JSON.stringify(this.obj)
    },
    titleDirty() {
      return this.title !== this.savedTitle
    },
    textDirty() {
      return this.text !== this.savedText
    },
    rawJSONDirty() {
      return this.rawJSON !== this.savedRawJSON
    },
  },
  methods: {
    removeTag(tag) {
      this.$store.dispatch('removeTag', {objId: this.objId, tag: tag})
    },
    addTag(tag) {
      this.$store.dispatch('addTag', {objId: this.objId, tag: tag})
    },
    addChild() {
      this.$store.dispatch('createChild', this.objId)
    },
    clone() {
      this.$store.dispatch('cloneObject', this.objId)
    },
    save() {
      let attrs = {}
      let saveFunction
      if (this.rawJSONDirty) {
        attrs = JSON.parse(this.rawJSON)
        saveFunction = 'updateObjectAttrs'
      } else {
        if (this.textDirty) attrs.text = {t: c.TYPE_STRING, v: this.text}
        if (this.titleDirty) attrs.title = {t: c.TYPE_STRING, v: this.title}
        saveFunction = 'changeObjectAttrs'
      }
      this.working = true
      this.workFailed = false
      this.$store.dispatch(saveFunction, {
        objId: this.objId,
        attrs: attrs,
        catchCallback: ()=>{
          this.workFailed = true
        },
        finallyCallback: ()=>{
          this.working = false
        }
      })
    },
    reset() {
      this.text = this.savedText
      this.title = this.savedTitle
      this.rawJSON = JSON.stringify(this.obj)
      this.newTag = ''
    },
    deleteObject() {
      this.$store.commit('setSelectedObjectId', null)
      this.$store.dispatch('deleteObject', this.objId)
    },
  },
  watch: {
    savedTitle(newVal) {
      this.title = newVal
    },
    savedText(newVal) {
      this.text = newVal
    },
    savedRawJSON(newVal) {
      this.rawJSON = newVal
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$button-margin: 0.5rem;

.object {
  max-width: 40rem;
  width: auto;
  display: inline-block;
}

h1 {
  display: inline-block;
  margin: 0;
}

.title {
  min-width: 11rem;
}

.tools,
.work-icons {
  font-size: 90%;
  display: inline-block;
  margin-left: $editable-padding;
}

.tools svg {
  margin-right: .75rem;
  cursor: pointer;
}

.tools svg:hover {
  color: $icon-hover-fg;
}

.tools svg:active {
  color: $icon-active;
}

.tools .delete {
  color: $icon-danger-fg;
}

.tools .delete:hover {
  color: $icon-danger-hover-fg;
}

.work-icons .work-failed {
  color: $error-fg;
}

.text {
  min-width: 4rem;
}

.tag-box {
  margin-top: $editable-padding;
  margin-left: $editable-padding;
}

.buttons {
  margin-top: 2 * $editable-padding;
  margin-right: $editable-padding;
  margin-bottom: $editable-padding;
  margin-left: $editable-padding;
  // To keep buttons on one line even on narrow screen.
  min-width: 21rem;
}

.buttons > button {
  margin-right: $button-margin;
}

.buttons-right {
  float: right;
}

.buttons-right button {
  margin-left: $button-margin;
}

.raw-edit {
  margin-top: 1rem;
}

.buttons-no-mouse :disabled {
  display: none;
}
</style>
