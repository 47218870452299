<template>
  <div class="ed-text">
    <contenteditable
      tag="div"
      :class="{editable: true, dirty: dirty}"
      :modelValue="modelValue"
      :noNL="noNL"
      @returned="$emit('submit', modelValue)"
      @update:modelValue="$emit('update:modelValue', $event)"
      @focus="this.isFocused = true"
      @blur="this.isFocused = false"
    />
    <div v-show="showPlaceholder" class="placeholder">{{ placeholder }}</div>
  </div>
</template>

<script>
import contenteditable from 'vue-contenteditable'

export default {
  name: 'EditableText',
  components: {
    contenteditable,
  },
  props: {
    // Validation fails from time to time - don't know why :(
    modelValue: { type: String, default: '', validate: ()=>true },
    noNL: { type: Boolean, default: false },
    dirty: { type: Boolean, default: false },
    placeholder: { type: String, default: 'EMPTY' },
  },
  data() {
    return {
      isFocused: false,
    }
  },
  computed: {
    showPlaceholder() {
      if(this.modelValue !== '') return false
      return !this.isFocused
    }
  },
}
</script>

<style scoped lang="scss">
.ed-text {
  position: relative;
}

.placeholder {
  position: absolute;
  font-weight: bold;
  color: $placeholder-fg;
  top: $editable-padding;
  left: $editable-padding;
  pointer-events: none;
}
</style>