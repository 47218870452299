<template>
  <li v-if="!isHidden">
    <div class="header" @click="clicked" v-if="!isRoot">
      <div :class="bulletClasses" />
      <div :class="{title: true, selected: isSelected}">{{ title }}</div>
    </div>
    <SimpleTreeChildren :ids="filteredChildrenIds"/>
  </li>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SimpleTreeItem',
  props: {
    objId: {type: Number, default: 0},
    isRoot: {type: Boolean, default: false},
    showDone: {type: Boolean, default: false},
  },
  data() {
    return {
      isOpen: this.isRoot,
    }
  },
  computed: {
    ...mapGetters(['objectAttr', 'objectChildrenIds']),
    isHidden() {
      return (!this.showDone && this.objectAttr(this.objId, '.tag.done') !== undefined)
    },
    bulletClasses() {
      let classes = {bullet: true}
      if(!this.isLeaf) classes.open = this.isOpen
      classes.leaf = this.isLeaf
      return classes
    },
    childrenIds() {
      return this.objectChildrenIds(this.objId)
    },
    // Needed for transition to work...
    filteredChildrenIds() {
      return (this.isOpen) ? this.childrenIds : []
    },
    title() {
      return this.objectAttr(this.objId, 'title', '[NO TITLE]')
    },
    // Only set to true when we are _sure_ it's a leaf.
    isLeaf() {
      if (!this.childrenIds) return false
      if (this.childrenIds.length === 0) return true
      return false
    },
    isSelected() {
      if(!this.$store.state.selectedObjectId) return false
      return this.objId === this.$store.state.selectedObjectId
    }
  },
  methods: {
    setOpen(val) {
        // lazy loading
        //if (val && !this.childrenIds) {
        //  this.$store.dispatch('fetchChildrenOf', this.objId)
        //}
        this.isOpen = val
    },
    clicked() {
      if(!this.isSelected) {
        if(!this.isOpen) {
          this.setOpen(true)
        }
        this.$store.commit('setSelectedObjectId', this.objId)
        return
      }
      this.setOpen(!this.isOpen)
    },
  },
  // see https://vuejs.org/v2/guide/components-edge-cases.html#Circular-References-Between-Components
  beforeCreate() {
    this.$options.components = {SimpleTreeChildren: require('./SimpleTreeChildren.vue').default}
    // eager loading
    this.$store.dispatch('fetchChildrenOf', this.objId)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$animation-speed: .2s;

.st-item {
}

.header {
  cursor: pointer;
}

.bullet {
  display: inline-block;
  width: 1rem;
  transition: all $animation-speed ease;
}

.bullet::before {
  content: "\203a";
}

.bullet.leaf::before {
  content: "\2022";
}

.bullet.open {
  transform: rotate(90deg) translate(6px,5px);
}

.title {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 10rem;
  overflow: hidden;
  vertical-align: top;
}

.selected {
  font-weight: bold;
}

ul {
  list-style-type: none;
  margin: 0.4rem 0 0.4rem 2px;
  padding-inline-start: 1rem;
  border-left: 1px dashed adjust-color($app-fg, $alpha: -0.85, $saturation: -0%);
}

li {
  margin-top: 0.2rem;
}

// children transition
.list-enter-active,
.list-leave-active {
  transition: all $animation-speed ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}
</style>