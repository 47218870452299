<template>
  <div :class="{'m-obj': true, selected: selected}" @click="$emit('selected')">
    <div class="title">{{ attrVal(obj, 'title') }}</div>
    <TagBox v-if="tags.length > 0"
            :obj="obj" size="85%" @tagClicked="$emit('tagClicked',$event)"/>
  </div>
</template>

<script>
import TagBox from '@/components/TagBox.vue'

export default {
  name: 'MiniObject',
  components: {
    TagBox,
  },
  props: {
    obj: {type: Object, default: () => ({})},
    selected: {type: Boolean, default: false},
  },
  computed: {
    tags() {
      let tags = []
      for(let key in this.obj) {
        if(key.startsWith('.tag.')) {
          tags.push(key.slice(5))
        }
      }
      return tags
    },
  },
  methods: {
    attrVal(obj, key, defVal) {
      if (obj[key] === undefined) {
        return defVal
      }
      return obj[key].v
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.m-obj {
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: $box-brd;
  box-shadow: $shadow;
  background-color: $box-bg;
  cursor: pointer;
}

.selected {
  border: $box-selected-brd;
  background-color: $box-selected-bg;
}

.title {
  font-size: 150%;
  font-weight: bold;
}

.tag-box {
  margin-top: 0.3rem;
}
</style>
