<template>
  <div id="view">
    <TopMenu/>
    <div id="view-body">
      <div id="view-nav">
        <SimpleTree/>
      </div>
      <div id="view-content">
        <div v-if="$store.state.selectedObjectId">
          <Object :obj="selectedObjectAttrs"/>
        </div>
        <div v-else class="placeholder">
          Choose an object from the tree.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Object from '@/components/Object.vue'
import SimpleTree from '@/components/SimpleTree.vue'
import TopMenu from '@/components/TopMenu.vue'

export default {
  name: 'SimpleTreeView',
  components: {
    Object,
    SimpleTree,
    TopMenu,
  },
  computed: {
    selectedObjectAttrs() {
      return this.$store.getters.objectAttrs(this.$store.state.selectedObjectId)
    }
  },
  beforeCreate() {
    this.$store.dispatch('fetchTagColors')
  }
}
</script>

<style scoped lang="scss">
#view-body {
  display: flex;
  overflow: auto;
}

#view-nav {
  padding: 3rem 2rem 2rem 2rem;
  flex: 0 0 auto;
}

#view-content {
  padding: 2rem;
  flex: 1 1 auto;
  min-height: 100vh;
}

.placeholder {
  font-size: 200%;
  font-weight: bold;
  margin: 7rem;
  color: adjust-color($placeholder-fg, $alpha: 0.1);
}
</style>
