<template>
  <div id="view">
    <TopMenu/>
    <div id="view-body">
      <div id="view-content">
        <div class="list">
          <div>
            <input
              v-model="filterText" placeholder="regex filter"
              :class="{filter: true, 'force-hover': filterText!==''}" type="text">
            <span v-show="filterText!==''" class="clear-filter"
              @click="filterText = ''">&#10006;</span>
          </div>
          <div class="obj-container">
            <MiniObject v-for="obj in filteredObjects" :key="obj['.id'].v"
                :obj="obj"
                :selected="selectedObjectId === obj['.id'].v"
                @selected="selectedObjectId = obj['.id'].v"
                @tagClicked="this.filterText = $event"
            />
          </div>
        </div>
        <div class="viewer">
          <Object v-if="selectedObjectId !== null" :obj="$store.state.objects[selectedObjectId]"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MiniObject from '@/components/MiniObject.vue'
import Object from '@/components/Object.vue'
import TopMenu from '@/components/TopMenu.vue'

export default {
  name: 'ListView',
  components: {
    MiniObject,
    Object,
    TopMenu,
  },
  data() {
    return {
      selectedObjectId: null,
      filterText: '',
    }
  },
  computed: {
    filteredObjects() {
      let re = new RegExp(this.filterText)
      let objs = []
      for(let objId in this.$store.state.objects) {
        if(objId === '0') continue;
        let obj = this.$store.state.objects[objId]
        // Hidden objects.
        if(!obj.title || obj['.global'] || obj['.tag.done'] ||
           obj['.smart.parent'] || obj.title.v.startsWith('.')) continue
        // Match title with regex.
        if (re.test(obj.title.v)) {
          objs.push(obj)
          continue
        }
        // Match tags with regex.
        for(let key in obj) {
          if(!key.startsWith('.tag.')) continue
          if(re.test(key.slice(5))) {
            objs.push(obj)
            break
          }
        }
      }
      return objs
    },
  },
  beforeCreate() {
    this.$store.dispatch('fetchAllObjects')
    this.$store.dispatch('fetchTagColors')
  }
}
</script>

<style scoped lang="scss">
#view-content {
  padding: 2rem;
  display: flex;
  overflow: auto;
}

.filter {
  margin-bottom: 1rem;
}

.clear-filter {
  opacity: 0.5;
  cursor: pointer;
  margin: 0 0.5rem;
  font-size: 130%;
  position: relative;
  top: 0.1rem;
}

.viewer {
  margin: 2rem 0 0 3.5rem ;
  min-width: 30vw;
}

.obj-container {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-around;
}

.obj-container .m-obj {
  margin: 0 0.5rem 0.5rem 0;
}

.filter::placeholder {
  color: adjust-color($placeholder-fg, $alpha: 0.5);
}
</style>
