<template>
    <transition-group name="list" tag="ul">
      <SimpleTreeItem v-for="childId in sortedIds" :key="childId"
        :objId="childId" />
    </transition-group>
</template>

<script>
import SimpleTreeItem from '@/components/SimpleTreeItem.vue'

export default {
  name: 'SimpleTreeChildren',
  components: {
    SimpleTreeItem,
  },
  props: {
    ids: {type: Array, default: () => [] },
  },
  computed: {
    sortedIds() {
      return this.ids.slice().sort((idA,idB) => {
        let titleA = (this.$store.state.objects[idA].title ?? {v: ''}).v
        let titleB = (this.$store.state.objects[idB].title ?? {v: ''}).v
        if(titleA === titleB) return 0
        if(titleA > titleB) return 1
        return -1
      })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$animation-speed: .2s;

ul {
  list-style-type: none;
  margin: 0.4rem 0 0.4rem 2px;
  padding-inline-start: 1rem;
  border-left: 1px dashed adjust-color($app-fg, $alpha: -0.85, $saturation: -0%);
}

li {
  margin-top: 0.2rem;
}

// children transition
.list-enter-active,
.list-leave-active {
  transition: all $animation-speed ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}
</style>