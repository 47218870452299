<template>
  <div class="tag-box" :style="style" @mouseover="mouseOver=true" @mouseleave="mouseOver=false">
    <div v-show="placeholder != '' && !newTagDirty && tags.length == 0" class="placeholder">{{ placeholder }}</div>
    <div class="tags">
      <Tag v-for="tag in tags" :key="tag" :tag="tag" :canRemove="editable"
           @tagClicked="$emit('tagClicked',$event)"
           @tagRemove="$emit('tagRemove',$event)"
      />
      <EditableText v-if="editable" v-show="mouseOver || newTag != ''"
        v-model="newTag" @submit="addTag" :noNL="true"
        :class="{'new-tag': true, dirty: newTagDirty}"
        placeholder="" style="min-width: 3rem" />
    </div>
  </div>
</template>

<script>
import EditableText from '@/components/EditableText.vue'
import Tag from '@/components/Tag.vue'

export default {
  name: 'TagBox',
  props: {
    obj: {type: Object, default: null},
    size: {type: String, default: "100%"},
    editable: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
  },
  components: {
    EditableText,
    Tag,
  },
  data() {
    return {
      newTag: '',
      mouseOver: false,
    }
  },
  computed: {
    objId() {
      return this.obj['.id'].v
    },
    tags() {
      let tags = []
      for(let key in this.obj) {
        if(key.startsWith('.tag.')) {
          tags.push(key.slice(5))
        }
      }
      return tags
    },
    style() {
      return {
        'font-size': this.size,
      }
    },
    newTagDirty() {
      return this.newTag !== ''
    },
  },
  methods: {
    addTag(evt) {
      this.newTag = ''
      this.$emit('tagAdd',evt)
    }
  },
  watch: {
    // Reset newTag if object ID changes.
    objId() {
      this.newTag = ''
    }
  },
}
</script>

<style scoped lang="scss">
.tag-box {
  position: relative;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  // To reserve ".tags" height when there are no tags and ".new-tag" is hidden.
  min-height: 1.85rem;
  // To reserve place for the placeholder;
  min-width: 12rem;
}

.new-tag {
  border-radius: $tag-radius;
  padding: $tag-padding;
  font-size: 100%;
  color: $tag-fg;
  border: $editable-hvr-brd;
}

.placeholder {
  position: absolute;
  font-size: 250%;
  font-weight: bold;
  color: $placeholder-fg;
  top: -0.35rem;
  pointer-events: none;
}
</style>

<!-- modify EditableText look -->
<style scoped>
.tags :deep() .editable {
  background: transparent;
  padding: 0;
  border: none;
}

.tags :deep() .editable:hover {
  border: none;
}
</style>
