import { createRouter, createWebHashHistory } from 'vue-router'
import SimpleTreeView from '@/views/SimpleTreeView.vue'
import ListView from '@/views/ListView.vue'

const routes = [
  { path: '/', redirect: '/tree' },
  {
    path: '/tree',
    name: 'Tree',
    component: SimpleTreeView
  },
  {
    path: '/list',
    name: 'List',
    component: ListView
  },
  //{
  //  path: '/about',
  //  name: 'About',
  //  // route level code-splitting
  //  // this generates a separate chunk (about.[hash].js) for this route
  //  // which is lazy-loaded when the route is visited.
  //  component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  //},
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
