<template>
  <SimpleTreeChildren :ids="childrenIds" class="items"/>
</template>

<script>
import SimpleTreeChildren from '@/components/SimpleTreeChildren.vue'

export default {
  name: 'SimpleTree',
  components: {
    SimpleTreeChildren,
  },
  computed: {
    childrenIds() {
      return this.$store.getters.objectChildrenIds(0)
    },
  },
  beforeCreate() {
    this.$store.dispatch('fetchChildrenOf', 0)
  }
}
</script>

<style scoped>
.items {
  margin: 0;
  padding-inline-start: 0;
  list-style-type: none;
  border: none;
}
</style>
