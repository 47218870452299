<template>
  <div v-show="tag !== null"
       :class="{tag: true, removable: canRemove}" :style="{'background-color': tagColor}"
       @click.stop="$emit('tagClicked',tag)"
  >
    <span class="text">{{ tag }}</span>
    <span v-if="canRemove" class="delete" @click.stop="$emit('tagRemove',tag)">&#10006;</span>
  </div>
</template>

<script>
export default {
  name: 'TagBox',
  props: {
    tag: {type: String, default: null},
    canRemove: { type: Boolean, default: false },
  },
  computed: {
    tagColor() {
      return this.$store.state.tagsColors[this.tag]
    }
  },
}
</script>

<style scoped lang="scss">
$spacing: 1rem;
.tag {
  padding: $tag-padding;
  margin-right: 0.45rem;
  border-radius: $tag-radius;
  border: $tag-brd;
  box-shadow: $shadow;
  background-color: #fff;
  color: $tag-fg;
  cursor: pointer;
}

.removable {
  padding: $tag-padding $tag-padding * 1.5;
}

.delete {
  opacity: 0.5;
  cursor: pointer;
  margin-left: $tag-padding;
}

.delete:hover {
  opacity: 1;
}
</style>
