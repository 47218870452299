<template>
  <div id="top-menu">
    <router-link to="/tree">Tree</router-link>
    <router-link to="/list">List</router-link>
  </div>
</template>

<style scoped lang="scss">
#top-menu {
  padding: 16px;
  font-size: 18px;
  background-color: $topm-bg;
  box-shadow: 0px 0px 6px rgba(0,0,0,0.16);
}

a {
  font-weight: bold;
  color: $topm-a-fg;
  margin: 0 0.5rem;
}

a.router-link-exact-active {
  color: $topm-a-act-fg;
}
</style>
