import { createStore } from 'vuex'
import axios from 'axios'
import c from '@/const'

const apiHost = (process.env.NODE_ENV==='development') ? `http://${window.location.hostname}:8081` : ''
const apiRoot =  `${apiHost}/api/v1`

function select(key, value) {
  let req = {"key": key}
  if (value) req.value = value
  return axios({
    method: 'post',
    url: `${apiRoot}/select`,
    data: JSON.stringify(req),
  })
}

export default createStore({
  state: {
    selectedObjectId: null,
    objects: {
      0: {'.id': {t: c.TYPE_ID, v: 0}},
    },
    childrenIds: {},
    tagsColors: {},
  },
  mutations: {
    setSelectedObjectId(state, objId) {
      state.selectedObjectId = objId
    },
    setTagsColors(state, obj) {
      let tagsColors = {}
      for(let key in obj) {
        if(!key.startsWith('.tag.')) continue
        tagsColors[key.slice(5)] = obj[key].v
      }
      state.tagsColors = tagsColors
    },
    updateObjectAttrsCache(state, {objId, attrs}) {
      console.log(`#${objId}: ${JSON.stringify(attrs)}`)
      state.objects[objId] = attrs
    },
    cacheObjectAttrs(state, attrs) {
      let objId = attrs['.id'].v
      if(!objId) {
        console.log(`invalid object: ${JSON.stringify(attrs)}`)
        return
      }
      // console.log(`#${objId} = ${JSON.stringify(attrs)}`)
      state.objects[objId] = attrs
    },
    setObjectChildrenIds(state, {objId, childrenIds}) {
      // console.log(`#${objId} children = ${JSON.stringify(childrenIds)}`)
      state.childrenIds[objId] = childrenIds
    }
  },
  actions: {
    updateObjectAttrs({commit}, {objId, attrs, catchCallback, finallyCallback}) {
      let promise = axios.put(
        `${apiRoot}/object/${objId}`,
        JSON.stringify(attrs)
      )
      .then(response => {
        if (response.status !== 200) {
          console.log(`Object save failed: status code ${response.status}`)
          return
        }
        commit('updateObjectAttrsCache', {objId: objId, attrs: attrs})
      })
      .catch(function(error) {
        console.log('Object save failed:', error)
        if(catchCallback) catchCallback()
      })
      if(finallyCallback) {
        promise.finally(finallyCallback)
      }
    },
    changeObjectAttrs({state,commit}, {objId, attrs, catchCallback, finallyCallback}) {
      let obj2 = {...state.objects[objId]}
      for(let key in attrs) {
        obj2[key] = attrs[key]
      }
      let promise = axios.put(
        `${apiRoot}/object/${objId}`,
        JSON.stringify(obj2)
      )
      .then(response => {
        if (response.status !== 200) {
          console.log(`Object save failed: status code ${response.status}`)
          return
        }
        commit('updateObjectAttrsCache', {objId: objId, attrs: obj2})
      })
      .catch((error) => {
        console.log('Object save failed:', error);
        if(catchCallback) catchCallback()
      })
      if(finallyCallback) {
        promise.finally(finallyCallback)
      }
    },
    cacheObjectsAttrs({commit}, objs) {
      for(let i=0; i<objs.length; i++) {
        commit('cacheObjectAttrs', objs[i])
      }
    },
    fetchChildrenOf({dispatch, commit}, objId) {
      // console.log(`Loading children of #${objId}`)
      axios.get(
        `${apiRoot}/object/${objId}/children`,
      )
      .then(response => {
        const children = response.data
        dispatch('cacheObjectsAttrs', children)
        let childrenIds = children.map(child => child['.id'].v)
        commit('setObjectChildrenIds', {objId: objId, childrenIds: childrenIds})
      })
    },
    createChild({dispatch}, parentId) {
      let attrs = {
        '.parent': {t: c.TYPE_ID, v: parentId},
        'title': {t: c.TYPE_STRING, v: 'New Object'},
        'text': {t: c.TYPE_STRING, v: ''},
      }
      const thenFunc = function({state,commit}, objId) {
        let childrenIds = state.childrenIds[parentId] || []
        childrenIds.push(objId)
        attrs['.id'] = {t: c.TYPE_ID, v: objId}
        commit('cacheObjectAttrs', attrs)
        commit('setObjectChildrenIds', {objId: parentId, childrenIds: childrenIds})
        commit('setSelectedObjectId', objId)
      }
      dispatch('createObject', {attrs, thenFunc})
    },
    cloneObject({state,dispatch}, obj1Id) {
      let obj1 = state.objects[obj1Id]
      if(!obj1) {
        console.log(`Clone failed: object ${obj1Id} does not exist`)
        return
      }
      let attrs = JSON.parse(JSON.stringify(obj1))
      if(attrs.title) {
        attrs.title.v = '[cloned] '+attrs.title.v
      } else {
        attrs.title = {t: c.TYPE_STRING, v: '[cloned]'}
      }
      delete attrs['.id']
      const thenFunc = function({state,commit}, obj2Id) {
        console.log(`cloned: #${obj1Id} -> #${obj2Id}`)
        attrs['.id'] = {t: c.TYPE_ID, v: obj2Id}
        commit('cacheObjectAttrs', attrs)
        const parentId = attrs['.parent'].v
        if(parentId) {
          let childrenIds = state.childrenIds[parentId] || []
          childrenIds.push(obj2Id)
          commit('setObjectChildrenIds', {objId: parentId, childrenIds: childrenIds})
        }
        commit('setSelectedObjectId', obj2Id)
      }
      dispatch('createObject', {attrs, thenFunc})
    },
    createObject(funcs, {attrs, thenFunc}) {
      axios.post(
        `${apiRoot}/objects`,
        JSON.stringify(attrs)
      )
      .then(response => {
        if (response.status !== 200) {
          console.log(`Object creation failed: status code ${response.status}`);
          return
        }
        thenFunc(funcs, +response.data)
      })
    },
    addTag({state, dispatch}, {objId, tag}) {
      console.log(`Adding tag "${tag}" to #${objId}`)
      let attrs2 = {...state.objects[objId]}
      attrs2[`.tag.${tag}`] = {t: c.TYPE_STRING, v: ''}
      dispatch('updateObjectAttrs', {objId: objId, attrs: attrs2})
    },
    removeTag({state, dispatch}, {objId, tag}) {
      console.log(`Removing tag "${tag}" from #${objId}`)
      let attrs = state.objects[objId]
      let attrs2 = {}
      for (let key in attrs) {
        if(key === `.tag.${tag}`) continue
        attrs2[key] = attrs[key]
      }
      dispatch('updateObjectAttrs', {objId: objId, attrs: attrs2})
    },
    deleteObject({state,dispatch}, objId) {
      axios.delete(
        `${apiRoot}/object/${objId}`,
      )
      .then(response => {
        if (response.status !== 200) {
          console.log(`Object deletion failed: status code ${response.status}`);
          return
        }
        let parentId = state.objects[objId]['.parent']
        if(parentId) {
          dispatch('fetchChildrenOf', parentId.v)
          // TODO: remove dangling ".parent"s
        }
        delete state.objects[objId]
      })
    },
    fetchAllObjects({state,commit,dispatch}) {
      select("")
      .then(response => {
        dispatch('cacheObjectsAttrs', response.data)
        for(let objID in state.objects) {
          let obj = state.objects[objID]
          if(obj['.global'] && !obj['.global'].v.startsWith('.tags.colors')) {
            commit('setTagsColors', obj)
            break
          }
        }
      })
    },
    fetchTagColors({commit,dispatch}) {
      select(".global")
      .then(response => {
        let objs = response.data
        dispatch('cacheObjectsAttrs', objs)
        for(let i=0; i<objs.length; i++) {
          let obj = objs[i]
          if(obj['.global'].v.startsWith('.tags.colors')) {
            commit('setTagsColors', obj)
          }
        }
      })
    },
  },
  modules: {
  },
  getters: {
    objectAttrs: (state) => (objId) => {
      return state.objects[objId]
    },
    objectAttr: (state) => (objId, key, defVal) => {
      let obj = state.objects[objId]
      if(!obj) return defVal
      let attr = obj[key]
      if(!attr) return defVal
      return attr.v
    },
    objectChildrenIds: (state) => (objId) => {
      return state.childrenIds[objId]
    },
  }
})
