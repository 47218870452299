<template>
  <router-view/>
</template>

<style lang="scss">
* {
  box-sizing: border-box;
  outline: 0px solid transparent;
}

a {
  text-decoration: none;
}

body {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $app-bg;
  color: $app-fg;
  min-height: 100vh;
}

button {
  background-color: $btn-bg;
  color: $btn-fg;
  border: $btn-brd;
  padding: $btn-padding;
  font-size: $btn-size;
  border-radius: 0.25rem;
  cursor: pointer;
}

button:hover:enabled {
  background-color: $btn-hover-bg;
  color: $btn-hover-fg;
  border: $btn-hover-brd;
}

button:active {
  background-color: $btn-active-bg;
  border: $btn-active-brd;
}

button:disabled {
  background-color: $btn-disabled-bg;
  color: $btn-disabled-fg;
  cursor: not-allowed;
}

button:hover:disabled {
  border: $btn-hover-brd;
}

input[type=text] {
  padding: 0.5rem;
  border-radius: 0.5rem;
  opacity: 0.9;
  //box-shadow: inset $shadow;
  background-color: $input-bg;
}

.editable {
  background-color: $editable-bg;
  border-radius: 0.5rem;
  padding: $editable-padding;
}

input[type=text],
.editable {
  border: 1px solid transparent;
}

input[type=text]:hover,
input[type=text].force-hover,
.editable:hover {
  border: $editable-hvr-brd;
}

.dirty {
  background-color: $dirty-bg;
}
</style>
